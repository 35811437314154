<template>
  <div class="relative">
    <portal to="header">
      <terminal-header
        :title="$t('Sorting')"
        @barcode="handleBarcode"
      />
    </portal>

    <q-carousel
      v-model="slide"
      transition-prev="scale"
      transition-next="scale"
      swipeable
      animated
      class="terminal-table-height"
    >
      <q-carousel-slide name="scan">
        <div class="relative q-py-sm fit">
          <portal
            v-if="item"
            to="bottom-message"
          >
            <div
              class="picking-hints text-center q-mb-md"
              v-html="tip"
            />
          </portal>

          <div v-else v-html="tip" class="terminal-footer terminal-footer--middle" />

          <order-item
            v-if="item"
            :remove-amount="true"
            :item="item.item"
          />
        </div>
      </q-carousel-slide>

      <q-carousel-slide name="itemForScan">
        <div class="relative q-py-sm fit">
          <portal
            v-if="items.length > 0"
            to="bottom-message"
          >
            <div class="picking-hints text-center q-mb-md">
              {{ $t('Items for scanning') }}
            </div>
          </portal>

          <div
            v-else
            class="terminal-footer terminal-footer--middle"
          >
            {{ $t('No items') }}
          </div>

          <div
            v-for="item in items"
            :key="item.id"
            class="q-mb-sm"
          >
            <order-item :item="item" />
          </div>
        </div>
      </q-carousel-slide>
    </q-carousel>
  </div>
</template>

<script>
// Vuex
import { mapMutations } from 'vuex'

export default {
  name: 'Sorting',
  data () {
    return {
      sortingPlace: null,
      action: 'scanPlace',
      slide: 'scan',
      tip: 'Scan sorting place',
      place: null,
      item: null,
      items: []
    }
  },
  methods: {
    ...mapMutations([
      'addErrorNotification'
    ]),
    scanPlace (barcode) {
      if (barcode.type !== 'S/P') {
        return this.addErrorNotification('You should scan place!')
      }

      const query = {
        action: 'get-place',
        criteria: {
          place: {
            id: barcode.value
          }
        }
      }

      return this.$service.sorting.getAll(query)
        .then(data => {
          this.tip = data.message
            ? data.message.message
            : 'Scan item'

          this.place = data
          this.action = 'scanItem'
          this.items = data.items
        })
    },
    scanItem (barcode) {
      const query = {
        action: 'get-item',
        data: {
          'sorting-place': this.place.sortingPlace
        },
        criteria: {
          item: {
            sku: barcode.raw
          }
        }
      }

      return this.$service.sorting.getAll(query)
        .then(item => {
          this.item = item
          this.action = 'scanDistributionPlace'
          this.tip = item.message.message || 'Scan empty distribution place'
          this.slide = 'scan'
        })
    },
    scanDistributionPlace (barcode) {
      if (barcode.type !== 'S/P') {
        return this.addErrorNotification('You should scan distribution place!')
      }

      const query = {
        action: 'scan-place',
        criteria: {
          state: ['new', 'normal']
        }
      }

      const data = {
        action: 'place-item',
        item: this.item.item.id,
        orderState: this.item.orderState,
        'place-item': this.item.placeItem,
        'sortingPlace': this.place.sortingPlace,
        targetPlace: this.item.distributionPlace
      }

      return this.$service.sorting.patch(barcode.value, data, query)
        .then(_ => {
          this.item = null
          this.action = 'scanItem'
          this.tip = 'Scan item'
        })
    },
    mutateAction (barcode) {
      if (barcode.type === 'S/P' && this.action !== 'scanDistributionPlace') {
        this.action = 'scanPlace'
      }
    },
    handleBarcode (barcode) {
      this.mutateAction(barcode)

      if (this[this.action]) {
        return this[this.action](barcode)
      }
    }
  }
}
</script>
